<template>
  <nav class="navbar bg-dark navbar-expand-lg bg-body-tertiary" data-bs-theme="dark">
    <div class="container-fluid mx-md-3 ">
      <a class="navbar-brand navbar-mobile-style" href="#">
        <img src="../image/32_32.png" alt="" width="32" height="32" class="d-inline-block "></a>
      <div class="navTextImage ">EarthSDK</div>
      <div class="navText pe-3 ps-1">v2.0</div>
      <div class="navText d-none d-md-block">丨 开源免费地球可视化二次开发框架</div>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end " id="navbarNav">
        <ul class="navbar-nav mb-2 mb-lg-0 nav ">

          <li class="nav-item px-1 d-none d-md-block">
            <a class="nav-link" :class="{ active: isActive('about') }" @click="setActive('about')" target="_blank"
              href="./earthui/index.html">立即体验</a>
          </li>

          <li class="nav-item px-1">
            <a class="nav-link" :class="{ active: isActive('about') }" @click="setActive('about')" target="_blank"
              href="./example/index.html">官方示例</a>
          </li>

          <li class="nav-item dropdown px-1">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
              aria-expanded="false"> 项目开发模板</a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><a class="dropdown-item" href="https://gitee.com/cesiumlab/earthsdk-demos/tree/master/app4-earth-ui"
                  target="_blank">earth-ui 模版</a>
              </li>

              <li><a class="dropdown-item"
                  href="https://gitee.com/cesiumlab/earthsdk-demos/tree/master/template-vue3-xe2-js"
                  target="_blank">vue3+js 模版</a>
              </li>
              <li><a class="dropdown-item"
                  href="https://gitee.com/cesiumlab/earthsdk-demos/tree/master/template-vue3-xe2-ts"
                  target="_blank">vue3+ts 模版</a>
              </li>
              <li><a class="dropdown-item"
                  href="https://gitee.com/cesiumlab/earthsdk-demos/tree/master/template-vue2-xe2-js"
                  target="_blank">vue2+js 模版</a>
              </li>
              <li><a class="dropdown-item"
                  href="https://gitee.com/cesiumlab/earthsdk-demos/tree/master/template-vue2-xe2-ts"
                  target="_blank">vue2+ts 模版</a>
              </li>
              <li><a class="dropdown-item"
                  href="https://gitee.com/cesiumlab/earthsdk-demos/tree/master/template-vite-vue3-xe2-ts"
                  target="_blank">vue3+vite+ts 模版 </a>
              </li>
              <li><a class="dropdown-item"
                  href="https://gitee.com/cesiumlab/earthsdk-demos/tree/master/test-esobj-vue3-xe2-ts"
                  target="_blank">vue3+ts 图层树模板</a>
              </li>
            </ul>
          </li>


          <li class="nav-item dropdown px-1">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              教程文档
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><a class="dropdown-item" href="https://wzonfjgpzd.feishu.cn/docx/NUssdX48WobIqlxU9dFcaRDhnwc"
                  target="_blank">1.初学者教程</a>
              </li>
              <li><a class="dropdown-item"
                  href="https://ng627s0rel.feishu.cn/docx/XzxLdpe6YoMPV9xu5pscmX2Fn9f?from=from_copylink"
                  target="_blank">2.自定义EarthSDK的UE场景</a>
              </li>
              <li><a class="dropdown-item"
                  href="https://ng627s0rel.feishu.cn/docx/NQCGdAo9wozBbWxdj32cYsytnSf?from=from_copylink"
                  target="_blank">3.加载UE中自定义的Actor</a>
              </li>

              <li><a class="dropdown-item"
                  href="https://ng627s0rel.feishu.cn/docx/IA1ZdRnoeoOuzVxZWiOcj9uXnzg?from=from_copylink"
                  target="_blank">4.加载UE自定义Widget(umg)</a>
              </li>


              <li><a class="dropdown-item"
                  href="https://ng627s0rel.feishu.cn/docx/YJgRdM3sYoSG77x6xgocRUYLnnc?from=from_copylink"
                  target="_blank">5.JS和UE自定义消息通道</a>
              </li>

              <li><a class="dropdown-item"
                  href="https://ng627s0rel.feishu.cn/docx/JkqzdBIwvoyBjQxN6rfccdNnnnf?from=from_copylink"
                  target="_blank">6.PAK打包</a>
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown px-1">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              视频教程
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><a class="dropdown-item"
                  href="https://space.bilibili.com/346212872/channel/collectiondetail?sid=2348836"
                  target="_blank">EarthSDK2.0初学者教程</a>
              </li>
              <!-- <li><a class="dropdown-item"
                  href="https://space.bilibili.com/346212872/channel/collectiondetail?sid=2365208"
                  target="_blank">EarthSDK2.0实操</a>
              </li> -->

              <li><a class="dropdown-item"
                  href="https://www.bilibili.com/video/BV17m411Z72S/?share_source=copy_web&vd_source=d22e21cd550e440da336286765a0818b"
                  target="_blank"> EarthSDK2.0像素流模式</a>
              </li>


              <li><a class="dropdown-item"
                  href="https://www.bilibili.com/video/BV1N1421S7uc/?share_source=copy_web&vd_source=d22e21cd550e440da336286765a0818b"
                  target="_blank">EarthSDK2.0本地大屏模式</a>
              </li>
            </ul>
          </li>
          <li class="nav-item px-1">
            <a class="nav-link" :class="{ active: isActive('about') }" @click="setActive('about')" target="_blank"
              href="https://www.wolai.com/earthsdk/nrp963KZyrXAtmGgUeuLRo">API文档</a>
          </li>
          <li class="nav-item px-1">
            <a class="nav-link" :class="{ active: isActive('about') }" @click="setActive('about')" target="_blank"
              href="https://ng627s0rel.feishu.cn/docx/GB3wduTRVo4UlSxCzmDcKGp6nRe?from=from_copylink">更新日志</a>
          </li>
          <li class="nav-item px-1 ">
            <a class="nav-link" :class="{ active: isActive('about') }" @click="setActive('about')" target="_blank"
              href="https://bjxbsj.cn/">地球可视化官网</a>
          </li>
          <li class="nav-item px-1 btn btn-primary d-flex align-items-center justify-content-center">
            <router-link class="nav-link " to="/PartnersProgram" target="_blank">
              合伙人计划
            </router-link>
            <!-- <router-link class="nav-link  " to="/PartnersProgram" target="_blank"  :class="{ active: isActive('PartnersProgram') }" >
              合伙人计划
            </router-link> -->
          </li>

        </ul>

      </div>
    </div>
  </nav>

</template>

<script setup>
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';
const activePage = ref('');
const route = useRoute();
function setActive(page) {
  activePage.value = page;
}
function isActive(page) {
  return activePage.value === page;

}

/**
 * 监听路由变化，设置当前活动页面
 */
watch(() => route.path, (newPath) => {
  setActive(newPath.replace('/', ''))
})

</script>

<style scoped>
.btn {
  height: 40px;
}

.btn .nav-link {
  color: #fff !important;

}

.navbar {
  min-height: 60px;
  /* 设置导航栏最小高度 */
  font-size: 16px;
  z-index: 11;
}

.navbar-toggler {
  border: none;
}

.navbar-nav .nav-link.active {
  color: #f0eded;

}

.navText {
  color: #e9e5e5;
  font-size: 16px;
  margin-right: auto;
}


.navTextImage {
  color: #fff;
  font-weight: bold;
  font-size: 18px;
}
</style>
